import { serverUrls } from "./constants";
import router from "@/router/index.js";

export const callAPI = async (method, path, body) => {
  return new Promise((resolve) => {
    fetch(serverUrls.authUrl + path, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
      body: body !== null ? JSON.stringify(body) : null,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode === 401) {
          localStorage.removeItem("authToken");
          router.push('/signin')
        }
        if ((res.statusCode >= 200 && res.statusCode < 300) || !res.statusCode) {
          resolve(res);
        }
      })
      .catch((err) => {
        console.error(err)
      })
  })
}
